import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Layout from '../components/Layout'
import Img from "gatsby-image";
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'


export default class ServicesPage extends React.Component {
  render() {
    const {data} = this.props
    const {edges: posts} = data.blogs

    return (<Layout>
      <div className="primary-contain">
        <Hero headerimg={data.headerimage.childImageSharp.fluid}/>
        <div className="long-contain">
          <div className="secondary-contain">
            <h1 className="has-text-weight-bold has-text-centered is-size-2 down-1">Services</h1>

            <section className="columns">

                {
                  posts.map(({node: post}) => (
                    <div className="column is-4">
                    <Link className="has-text-primary" to={post.fields.slug}>
                    <div className="service-larger" key={post.id}>
                      <Img className="service-img" fixed={post.frontmatter.image.childImageSharp.fixed}/>
                      <Link className="has-text-primary" to={post.fields.slug}>
                        <h2 className="is-size-4">{post.frontmatter.title}</h2>
                      </Link>
                    <p>
                      {post.excerpt}
                      <br/>
                    </p>
                    <Link className="button" to={post.fields.slug}>Read More </Link>
                    </div></Link></div>))
                }
            </section>
          </div>
        </div>
        <ContactForm />
      </div>
    </Layout>)
  }
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({edges: PropTypes.array})
  })
}

export const pageQuery = graphql `
  query ServicesQuery {
    headerimage: file(relativePath: {eq: "contactbg.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    blogs: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "service" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 160)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            image {
              childImageSharp {
                fixed(width: 400, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
